import React from "react";

import { useLocation } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const BlogExpanded = () => {
  const location = useLocation();
  const { state: blogData } = location;

  return (
    <>
      {blogData && (
        <Card>
          <CardContent key={blogData.id}>
            {blogData.image && (
              <img
                src={blogData.image}
                alt="Blog Image"
                style={{
                  marginBottom: "16px",
                  width: "97vw",
                  height: "45vh",
                  objectFit: "cover",
                }}
              />
            )}
            <Typography
              variant="h5"
              component="h2"
              gutterBottom
              style={{ width: "70%", fontSize: 45, fontFamily: "sans-serif" }}
            >
              {blogData.title}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              {blogData.username} | {blogData.time}
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
              style={{ width: "70%", fontSize: 25, fontFamily: "sans-serif" }}
            >
              {blogData.description}
            </Typography>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default BlogExpanded;
